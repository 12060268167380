<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="900px"
      :persistent="load"
      scrollable
    >
      <v-card>
        <v-card-title>
          Carga masiva de gift cards
          <v-spacer></v-spacer>
          <v-btn
            :disabled="load"
            icon
            @click="dialog = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form">
            <v-row class="pt-8">
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-autocomplete
                  v-model="tipo"
                  label="Tipo"
                  item-text="nombre"
                  item-value="id"
                  tabindex="1"
                  :items="tipos_gf"
                  :disabled="load || bloquear"
                  :rules="[rules.required]"
                  validate-on-blur
                  autofocus
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-autocomplete
                  v-model="estado"
                  label="Estado"
                  item-text="nombre"
                  item-value="id"
                  tabindex="1"
                  :items="estados"
                  :disabled="load || bloquear"
                  :rules="[rules.required]"
                  validate-on-blur
                  outlined
                  readonly
                  filled
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="4"></v-col>
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-autocomplete
                  v-model="sucursal"
                  label="Sucursal"
                  item-text="nombre"
                  item-value="id"
                  tabindex="1"
                  :items="$store.state.sucursales"
                  :disabled="load || bloquear"
                  :rules="[rules.required]"
                  validate-on-blur
                  outlined
                  dense
                  @change="get_vendedores()"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-autocomplete
                  v-model="tipo_ent"
                  label="Tipo destinatario"
                  item-text="nombre"
                  item-value="id"
                  tabindex="1"
                  :items="tipos_ent"
                  :disabled="load || bloquear"
                  :rules="[rules.required]"
                  validate-on-blur
                  outlined
                  readonly
                  filled
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" md="4" class="py-0">
                <FechaPickerMes
                  :key="fechaKey"
                  :disabled="load || bloquear"
                  :fecha.sync="periodo"
                  :label="true"
                  :clearable="false"
                  @change="get_vendedores()"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-autocomplete
                  v-model="caja"
                  label="Caja"
                  item-text="nombre"
                  item-value="id"
                  tabindex="1"
                  :items="cajas_user"
                  :disabled="load || bloquear"
                  :readonly="multiple"
                  :filled="multiple"
                  outlined
                  dense
                  @change="set_cajas_vendedores"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="4" class="py-0">
                <v-switch
                  v-model="multiple"
                  class="pt-1 mt-0"
                  label="Multiples cajas"
                  tabindex="1"
                  :disabled="load || bloquear"
                  hide-details
                  dense
                  @change="caja = null"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>

          <v-data-table
            class="cebra mt-2"
            :items-per-page="-1"
            :headers="headers"
            :items="vendedores"
            sort-by="nombre"
            hide-default-footer
            dense
          >
            <template v-slot:[`item.caja_sel`]="{ item }">
              <v-autocomplete
                v-model="item.caja"
                class="my-2"
                item-text="nombre"
                item-value="id"
                :tabindex="multiple ? 2 : ''"
                :items="cajas_user"
                :disabled="load || bloquear"
                :readonly="!multiple"
                :filled="!multiple"
                hide-details
                dense
              ></v-autocomplete>
            </template>
            <template v-slot:[`item.monto_inp`]="{ item }">
              <v-text-field
                v-model.trim="item.importe"
                v-mask="mask"
                class="my-2"
                type="tel"
                prefix="$"
                tabindex="2"
                :disabled="load || bloquear"
                hide-details
                dense
              ></v-text-field>
            </template>
            <template v-slot:[`item.icon`]="{ item }">
              <v-icon
                :title="item.title ? item.title : 'Pendiente'"
                :color="item.color"
                small
              >
                {{ item.icono ? item.icono : 'far fa-clock' }}
              </v-icon>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                :disabled="load || bloquear"
                title="Quitar"
                color="error"
                small
                @click="quitar_vendedor(item)"
              >
                fas fa-times-circle
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-alert
                class="mx-auto mt-4"
                max-width="400"
                type="warning"
                border="left"
                dense
                text
              >
                No hay datos para los filtros seleccionados
              </v-alert>
            </template>
          </v-data-table>

        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-4">
          <v-btn
            color="error"
            class="mr-2"
            :disabled="load"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="bloquear && !load"
            color="info"
            @click="imprimir_masivo"
          >
            <v-icon left>fas fa-print</v-icon>
            Imprimir
          </v-btn>
          <BtnConfirmar
            v-if="!bloquear"
            :loading="load"
            :disabled="vendedores.length === 0"
            @action="guardar"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <PDFViewer
      v-model="dialog_pdf"
      :pdf="pdf"
      nombre="incentivo-masivo"
      titulo="Gift Card masiva"
    />

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { get_last_periodo, number_mask } from '../../util/utils'
import { giftcardA4_masiva } from '../../util/plantillas/pdfs'
import FechaPickerMes from '../util/FechaPickerMes'
import BtnConfirmar from '../util/BtnConfirmar'
import PDFViewer from '../../util/plantillas/PDFViewer'

export default {
  data () {
    return {
      mask: number_mask,
      fechaKey: 0, // para refrescar el componente de periodo
      pdf: null,
      dialog_pdf: false,
      load: false,
      bloquear: false, // esta variable se usa cuando hace click en guardar para bloquar el boton y algunos input
      multiple: false,
      tipo: 2,
      estado: 3,
      sucursal: null,
      tipo_ent: 1,
      periodo: get_last_periodo(),
      caja: null,
      vendedores: [],
      id_gf_cargadas: [],
      headers: [
        { text: 'Nombre', value: 'nombre', sortable: false },
        { text: 'Caja', value: 'caja_sel', sortable: false },
        { text: 'Monto', value: 'monto_inp', sortable: false, align: 'end' },
        { text: 'Estado', value: 'icon', sortable: false, align: 'center'},
        { text: '', value: 'actions', sortable: false },
      ],
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  created () {
    if (this.$store.state.sucursales.length === 1) {
      this.giftcard.sucursal = this.$store.state.sucursales[0].id
    }
  },
  components: {
    FechaPickerMes,
    BtnConfirmar,
    PDFViewer,
  },
  props: {
    value: Boolean,
    tipos_ent: Array,
    tipos_gf: Array,
    estados: Array,
  },
  computed: {
    ...mapState('giftcards', ['cajas_user']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    dialog (val) {
      val || this.clear()
    }
  },
  methods: {
    async imprimir_masivo () {
      this.$store.state.loading = true
      let data = []
      for (const index in this.id_gf_cargadas) {
        const id = this.id_gf_cargadas[index]

        await this.$store.dispatch('giftcards/get_data_print', { id: id })
          .then((res) => {
            // guarda los datos para imprimir
            data.push(res.data)
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
      }

      this.pdf = await giftcardA4_masiva(data)
      this.dialog_pdf = true

      this.$store.state.loading = false
    },
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        try {
        const validacion = await this.validar()

        if (validacion === 1) {
          //console.log('cumple con los requisitos para empezar a cargar las gifcard')
          this.bloquear = true
          let errores = 0

          // recorre todos los vendedores y carga una por una las giftcards
          for (const index in this.vendedores) {
            let item = this.vendedores[index]

            // formatea el importe
            let importe = parseInt(item.importe.replaceAll('.', ''))

            // carga la gift card
            await this.$store.dispatch('giftcards/alta_virtual', {
              caja_jer: item.caja,
              sucursal: this.sucursal,
              entidad: item.id,
              tipo: this.tipo,
              estado: this.estado,
              importe: importe,
              periodo: this.periodo,
            })
              .then((res) => {
                // guarda el id de la giftcard por si luego quiere imprimirla
                this.id_gf_cargadas.push(res.id)
                // setea la row con un icono de exito
                item.icono = 'fas fa-check'
                item.color = 'success'
                item.title = 'Correcto'
              })
              .catch(error => {
                // setea la row con una advertencia y el mensaje de error
                item.icono = 'fas fa-exclamation-triangle'
                item.color = 'warning'
                item.title = error.message
                errores += 1
              })

          }

          // mensaje personalizado
          let icono = 'success'
          let mensaje = `Carga masiva finalizada correctamente <p style="font-size: 22px; margin-top: 12px">Se guardaron ${this.vendedores.length} gift cards</p>`
          if (errores > 0) {
            icono = 'warning'
            mensaje = `Carga masiva finalizada, revise el estado de cada destinatario
                      <p style="font-size: 22px; margin-top: 12px; margin-bottom: 0px">Correctas: ${this.vendedores.length - errores} <br/> Con error: ${errores}</p>`
          }
          this.$swal.fire({
            icon: icono,
            title: mensaje
          })
          
        }
        } catch (error) {
          this.$store.dispatch('show_snackbar', {
            text: 'Fallo al guardar las gift cards: ' + error,
            color: 'error'
          })
        }
        this.load = false
      }
    },
    // el metodo validar devuelve un 1 o 0 en funcion a las validaciones necesarias para cargar las giftcards
    async validar () {
      try {

      if (this.multiple) {
        /** 
         * CASO EN EL QUE SAQUE EL IMPORTE DE DISTINTAS CAJAS
         */

        // en esta primer condicion se verifica que el usuario no haya dejado cajas sin seleccionar en la lista
        let condicion_1 = true
        this.vendedores.forEach(item => {
          if (!item.caja) {
            this.$store.dispatch('show_snackbar', {
              text: 'Debe seleccionar una Caja para cada destinatario',
              color: 'warning',
            })
            condicion_1 = false
            return
          }
        })

        // si cumple la condicion 1 recien pasa a verificar la condicion 2
        if (condicion_1) {
          
          // en la segunda condicion agrupa a los vendedores por caja, suma sus montos totales
          // y verifica que cada caja este abierta y cuente con el saldo suficiente
          let condicion_2 = true

          // agrupa a los vendedores por caja
          let group_by_caja = this.vendedores.reduce(function (r, a) {
              r[a.caja] = r[a.caja] || []
              r[a.caja].push(a)
              return r
          }, Object.create(null))

          // ciclo for por cada grupo de vendedores con la misma caja
          for (const key in group_by_caja) {
            const group = group_by_caja[key]
            const caja_jer = parseInt(key)
            let monto_total = 0

            // calcula el monto total de la caja de acuerdo al array de vendedores agrupados
            for (const index in group) {
              const item = group[index]
              monto_total += parseInt(item.importe.replaceAll('.', ''))
            }

            // key es el id de la caja, verifica si la caja esta abierta y si tiene saldo suficiente
            //console.log(`la caja ${this.cajas_user.find(c => c.id === parseInt(key)).nombre} necesita extraer un monto de $ ${monto_total}`)
            const caja_nombre = this.cajas_user.find(c => c.id === caja_jer).nombre

            await this.$store.dispatch('caja/abierta', { caja_jer: caja_jer })
              .then(async (data) => {
                const caja = data.numero
                await this.$store.dispatch('caja/comprobar_saldo', {
                  caja: caja,
                  importe: monto_total,
                })
                  .then(() => {
                    // caja abierta y con saldo suficiente
                  })
                  .catch(error => {
                    this.$store.dispatch('show_snackbar', {
                      text: `La caja ${caja_nombre} no cuenta con $ ${monto_total} en efectivo para realizar la operación`,
                      color: 'error',
                    })
                    condicion_2 = false
                    return
                  })
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: `La caja ${caja_nombre} no se encuentra abierta`,
                  color: 'error',
                })
                condicion_2 = false
                return
              })

          }

          if (condicion_2) {
            return 1
          } else {
            return 0
          }
          
        } else {
          return 0
        }

      } else {
        /** 
         * CASO EN EL QUE SAQUE EL IMPORTE DE UNA SOLA CAJA
         */

        // verifica que haya seleccionado una caja "general"
        if (this.caja) {

          let condicion = true

          // calcula el monto total
          let monto_total = 0
          for (const index in this.vendedores) {
            const item = this.vendedores[index]
            monto_total += parseInt(item.importe.replaceAll('.', ''))
          }

          // valida una sola vez la caja abierta y el monto total ya que todos usan la misma
          await this.$store.dispatch('caja/abierta', { caja_jer: this.caja })
            .then(async (data) => {
              const caja = data.numero
              await this.$store.dispatch('caja/comprobar_saldo', {
                caja: caja,
                importe: monto_total,
              })
                .then(() => {
                  // la caja esta abierta y cuenta con el saldo necesario
                })
                .catch(error => {
                  this.$store.dispatch('show_snackbar', {
                    text: error.message,
                    color: 'error',
                  })
                  condicion = false
                })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error',
              })
              condicion = false
            })

          if (condicion) {
            return 1
          } else {
            return 0
          }

        } else {
          this.$store.dispatch('show_snackbar', {
            text: 'Debe seleccionar una Caja',
            color: 'warning',
          })
          return 0
        }
      }

      } catch (error) {
        this.$store.dispatch('show_snackbar', {
          text: 'Fallo al validar las cajas: ' + error,
          color: 'error'
        })
        return 0
      }
    },
    async get_vendedores () {
      if (this.sucursal && this.periodo) {
        this.$store.state.loading = true
        this.vendedores = []
        await this.$store.dispatch('giftcards/get_resumen_objetivos', {
          sucursal: this.sucursal,
          periodo: this.periodo,
        })
          .then(data => {
            this.vendedores = data.entidades
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.$store.state.loading = false
      }
    },
    quitar_vendedor (item) {
      const index = this.vendedores.indexOf(item)
      this.vendedores.splice(index, 1)
    },
    set_cajas_vendedores () {
      this.vendedores.forEach(entidad => {
        entidad.caja = this.caja
      })
    },
    clear () {
      this.$refs.form.resetValidation()
      this.fechaKey += 1
      this.bloquear = false
      this.multiple = false
      this.tipo = 2
      this.estado = 3
      this.sucursal = null
      this.tipo_ent = 1
      this.periodo = get_last_periodo()
      this.caja = null
      this.vendedores = []
      this.id_gf_cargadas = []
    }
  }
}
</script>