<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    :persistent="load"
  >
    <v-card>
      <v-card-title>
        Nueva gift card
        <v-spacer></v-spacer>
        <v-btn
          :disabled="load"
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-autocomplete
                v-model="tipo"
                label="Tipo"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :items="tipos_gf"
                :disabled="load"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-autocomplete
                v-model="estado"
                label="Estado"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :items="estados"
                :disabled="load"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                readonly
                filled
                dense
              ></v-autocomplete>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="4"></v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-autocomplete
                v-model="sucursal"
                label="Sucursal"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :items="$store.state.sucursales"
                :disabled="load"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="get_destinatarios()"
              ></v-autocomplete>
            </v-col>
             <v-col cols="12" sm="6" md="4" class="py-0">
              <v-autocomplete
                v-model="tipo_ent"
                label="Tipo destinatario"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :items="tipos_ent"
                :disabled="load"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
                @change="get_destinatarios()"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-autocomplete
                v-model="entidad"
                label="Destinatario"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :items="entidades"
                :disabled="load"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <v-autocomplete
                v-model="caja"
                label="Caja"
                item-text="nombre"
                item-value="id"
                tabindex="1"
                :items="cajas_user"
                :disabled="load"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" md="4" class="py-0">
              <FechaPickerMes
                :disabled="load"
                :fecha.sync="periodo"
                :label="true"
                :clearable="false"
              />
            </v-col>
            <v-col cols="6" md="4" class="py-0">
              <v-text-field
                v-model="importe"
                v-mask="mask"
                type="tel"
                label="Importe"
                prefix="$"
                tabindex="1"
                :disabled="load"
                :rules="[rules.required]"
                validate-on-blur
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { get_last_periodo, number_mask } from '../../util/utils'
import FechaPickerMes from '../util/FechaPickerMes'
import BtnConfirmar from '../util/BtnConfirmar'

export default {
  data () {
    return {
      mask: number_mask,
      load: false,
      tipo: 2,
      estado: 3,
      sucursal: null,
      tipo_ent: null,
      periodo: get_last_periodo(),
      caja: null,
      importe: '',
      entidad: null,
      entidades: [],
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  async created () {
    // obtiene las cajas del usuario
    this.$store.state.loading = true
    await this.$store.dispatch('giftcards/get_cajas')
    this.$store.state.loading = false
    // selecciona la sucursal si tiene solo 1
    if (this.$store.state.sucursales.length === 1) {
      this.giftcard.sucursal = this.$store.state.sucursales[0].id
    }
  },
  components: {
    FechaPickerMes,
    BtnConfirmar,
  },
  props: {
    value: Boolean,
    tipos_ent: Array,
    tipos_gf: Array,
    estados: Array,
  },
  computed: {
    ...mapState('giftcards', ['cajas_user']),
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        const importe = parseInt(this.importe.replaceAll('.', ''))

        // valida caja abierta con this.caja, eso devuelve el num de caja y ese se usa para validar el monto y cargar la giftcard
        await this.$store.dispatch('caja/abierta', { caja_jer: this.caja })
          .then(async (data) => {

            const caja = data.numero
            await this.$store.dispatch('caja/comprobar_saldo', {
              caja: caja,
              importe: importe,
            })
              .then(async () => {
              
                // carga la gift card
                await this.$store.dispatch('giftcards/alta_virtual', {
                  caja_jer: this.caja,
                  sucursal: this.sucursal,
                  entidad: this.entidad,
                  tipo: this.tipo,
                  estado: this.estado,
                  importe: importe,
                  periodo: this.periodo,
                })
                  .then(() => {
                    // si todo salio bien, muestra un modal y cierra el dialog
                    this.dialog = false
                    this.$swal.fire({
                      icon: 'success',
                      title: `Gift Card cargada correctamente`
                    })
                  })
                  .catch(error => {
                    this.$store.dispatch('show_snackbar', {
                      text: error.message,
                      color: 'error',
                    })
                  })

              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error',
                })
              })

          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.load = false
      }
    },
    async get_destinatarios () {
      if (this.sucursal && this.tipo_ent) {
        this.$store.state.loading = true
        this.entidades = []
        this.entidad = null
        await this.$store.dispatch('giftcards/get_destinatarios', {
          sucursal: this.sucursal,
          tipo: this.tipo_ent,
        })
          .then(data => {
            this.entidades = data.entidades
          })
          .catch(error => {
            this.$store.dispatch('show_snackbar', {
              text: error.message,
              color: 'error',
            })
          })
        this.$store.state.loading = false 
      }
    }
  }
}
</script>